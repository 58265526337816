import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ChallengeClass from "./ChallengeClass";
import { updateParticipantSquares } from '../actions/participantActions';
import { selectedParticipant } from "../actions/participantActions";
import { computeHeadingLevel } from "@testing-library/react";
import MileLog from "./MileLog";

class ChallengeBody extends Component {

    // todaysDate = new Date();
    // todaysDateComponents = {
    //     day : this.todaysDate.getDate(),
    //     month : this.todaysDate.getMonth() + 1,
    //     year : this.todaysDate.getFullYear()
    // }

    state = {
        classesSaved: false,
        milesDate : "",
        milesAdded : 0,
        milesComment : "",
        showMiles : false
    }

    updatedClasses = [];
    updatesMade = false;

    saveChanges = (e) => {
        e.preventDefault()
        this.updatedClasses = this.updatedClasses.filter((square, index) => this.updatedClasses.indexOf(square) === index ? square : null);
        let copyParticipant = this.copyObj(JSON.stringify(this.props.participantInfo));

        this.updatedClasses.forEach(square => {
            copyParticipant.game.squares[square.squareID] = square
        });

        copyParticipant.percentComplete =
            Math.round((copyParticipant.game.squares.reduce((counter, square) =>
                square.complete === true ? counter += 1 : counter, 0)
                / this.props.participantInfo.game.squares.length) * 100);

        if (this.state.milesAdded > 0) {
            copyParticipant.milesLog.push({
                date : this.state.milesDate,
                miles : this.state.milesAdded,
                comment : this.state.milesComment
            }
            );
          
            copyParticipant.milesCompleted += this.state.milesAdded;
        }

        this.setState({
            milesDate : "",
            milesAdded : 0,
            milesComment : ""
        })

        this.props.updateParticipantSquares(copyParticipant);
        this.setState({ classesSaved: true });
        // this.updatesMade = this.state.classesSaved;

    }

    updateMilesDate = (e) => {
        this.setState({ 
            milesDate : e.target.value
        })
    }

    updateMiles = (e) => {
        this.setState({
            milesAdded : +e.target.value
        })
    }

    updateMilesComment = (e) => {
        this.setState({
            milesComment : e.target.value
        })
    }

    showMiles = () => {
        this.setState({
            showMiles : !this.state.showMiles
        })
    }

    holdChanges = (square) => {
        this.updatedClasses.push(square);
    }
    copyObj = (objStr) => JSON.parse(objStr);

    render() {

        return (
            <div>
                <h2 className="white-font">{this.props.participantInfo.displayLeaderboard}</h2>
                <h3 className={this.state.classesSaved ? "" : "hide"}>Updates have been saved.</h3>
                <h3 className={this.props.participantInfo.percentComplete === 100 ? "" : "hide"}>Congrats! You have completed the challenge!</h3>

                <form onSubmit={this.saveChanges}>
                    <div className="display-class">
                        {this.copyObj(JSON.stringify(this.props.participantInfo.game.squares))
                            .sort((val1, val2) => (new Date(val1.planDate) - new Date(val2.planDate)))
                            .map(square => <ChallengeClass key={square.squareID} square={square} captureChange={this.holdChanges} />)}
                    </div>
                    <div>
                        <label className="emphasis-text" htmlFor="miles">Date / Miles / Note</label>
                        <input type="date" onChange={(event) => this.updateMilesDate(event)} id="milesDate" name="milesDate" value={this.state.milesDate} />
                        <input type="number" onChange={(event) => this.updateMiles(event)} id="milesCompleted" name="milesCompleted" value={this.state.milesAdded}/>
                        <input type="text" onChange={(event) => this.updateMilesComment(event)} id="milesComment" name="milesComment" value={this.state.milesComment}/>
                    </div>
                    <button className="save_updates">Save</button>
                </form>

                <button className="save_updates" onClick={this.showMiles}>View Mile Log</button>

                <div className={this.state.showMiles ? '' : 'hide'}>        
                <div>Goal: {this.props.participantInfo.mileageGoal}</div>
                <div>Completed: {this.props.participantInfo.milesCompleted}</div>
                <div>
                    {this.props.participantInfo.milesLog.map((log, index) => <MileLog key={index} log={log}></MileLog>)}
                </div>
                </div>
                <h5 className="white-font"><p>Bike/Tread: 20 minutes <br />
                    Strength/Yoga: 10 minutes <br />
                    Meditation/Stretch: 5 minutes <br />
                    Always welcome to extend the listed target times!</p></h5>
                <h4 className="white-font">Don't forget to check off those squares and save!</h4>
            </div>
        )
    }
}

const mapStateToProps = (state) =>
({
    participantInfo: state.participant.participants[state.participant.selectedParticipant],
    // participantSquares : state.participant.participants[state.participant.selectedParticipant].game.squares
    // selectedParticipantIndex : state.participant.selectedParticipant 
});

const mapDispatchToProps = dispatch => bindActionCreators({
    updateParticipantSquares
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeBody)