import { classList } from "./classList"
const maxCardio = 15;
const minInstructorCt = 5;
const kvPair = ['strength:8', 'yoga:6', 'wild card:4', 'stretch:6', 'meditation:5'];

class BingoCard {
    constructor(cardType) {
        this.cardType = cardType;
        this.bingoCard = [];
        this.classList = classList;
    }

    buildBingoCard() {

        this.classList.forEach(item => { item.timesUsed = 0 });
        
        let classUseCap = 1;
        let countTypes = this.cardType.split('+');
        let maxType = Math.round(maxCardio / countTypes.length);
        
        countTypes.forEach(type => {
            if (type === 'row' || this.cardType === 'power zone' ) classUseCap = Math.ceil(maxType / minInstructorCt);

            this.getSquares(this.bingoCard.length, maxType, classUseCap, type);

        })

        classUseCap = 1;
        kvPair.forEach( type => {
            let [key, value] = type.split(':');
            this.getSquares(this.bingoCard.length, Number(value), classUseCap, key);
        })

        return this.bingoCard;
    }

    findClass(classUseCap, cardType) {

        let classFound = false;
        let randomNum = 0;

        while (!classFound) {
            randomNum = Math.floor(Math.random() * this.classList.length);

            if (this.classList[randomNum].classType === cardType &&
                this.classList[randomNum].timesUsed < classUseCap) {
                this.classList[randomNum].timesUsed++;
                classFound = true;
            }
        }

        return this.classList[randomNum];
    }

    getSquares(squareIndex, numSquares, classUseCap, cardType) {

        let squareCount = squareIndex + numSquares;
        for (squareIndex; squareIndex < squareCount; squareIndex++) {
            let foundSquare = this.findClass(classUseCap, cardType);

            this.bingoCard.push({
                squareID: squareIndex,
                instructor: foundSquare.instructor,
                classType: foundSquare.classType,
                planDate: null,
                complete: false
            });
        }
    }

}

export default BingoCard
